import React, {useContext, useState, useEffect} from 'react';
import { getDay } from '../../utils/getDates';
import CalendarContext from '../../context/CalendarContext';
import Day from './Day';

const DayCalendar = ({makeDetailed, setEventToDetail, weekClick, monthClick}) => {
    const [currentDay, setCurrentDay] = useState(getDay());
    const {dayIndex, monthIndex, yearIndex} = useContext(CalendarContext)
    
    useEffect(() => {
        setCurrentDay(getDay(dayIndex, monthIndex));
    }, [dayIndex, monthIndex]);

    return (
        <React.Fragment>
                <Day day={currentDay} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail} weekClick={weekClick} monthClick={monthClick}/>
        </React.Fragment>
    );
}

export default DayCalendar;
