import React, { useEffect, useState } from "react";
import { API_AUTH_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MyInputText from "../UI/input/MyInputText";
import MyButton from "../UI/button/MyButton";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

const RegisterForm = () => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [middleName, setMiddleName] = useState(null);
  const [email, setEmail] = useState(null);

  const {setGeneralError} = useContext(AuthContext)
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [middleNameError, setMiddleNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();

  console.log(API_AUTH_URL)

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setFirstNameError(null);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setLastNameError(null);
  };

  const handleMiddleNameChange = (event) => {
    setMiddleName(event.target.value);
    setMiddleNameError(null);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    axios({
      method: "POST",
      url: `${API_AUTH_URL}/api/v1/register/`,
      data: {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        email: email,
      },
    })
      .then((res) => {
        if (res.data.IntegrityError === true) {
          alert("IntegrityError");
        } else {
          navigate("/register/confirm", { replace: true });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.first_name) {
          setFirstNameError(e.response.data.first_name);
        }
        if (e.response.data.last_name) {
          setLastNameError(e.response.data.last_name);
        }
        if (e.response.data.email) {
          setEmailError(e.response.data.email);
        } 
        if (e.response.data.detail) {
          setGeneralError([e.response.status, e.response.data.detail]);
        } else if (e.response.data === '') {
          setGeneralError(['999', 'Enter data'])
        }
      });
  };

  return (
    <div>
      <div className="register-form-block">
        <div className="enter-name-block">
          Введите Имя:
          <MyInputText onChange={(event) => handleFirstNameChange(event)} onKeyDown={handleKeyDown} />
          {firstNameError ? (
            <div className="errors-message-class">{firstNameError}</div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="enter-lastname-block enter-name-block">
          Введите Фамилию:
          <MyInputText onChange={(event) => handleLastNameChange(event)} onKeyDown={handleKeyDown} />
          {lastNameError ? (
            <div className="errors-message-class">{lastNameError}</div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="enter-email-block enter-name-block">
          Введите почту:
          <MyInputText onChange={(event) => handleEmailChange(event)} onKeyDown={handleKeyDown} />
          {emailError ? (
            <div className="errors-message-class">{emailError}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <MyButton onClick={handleSubmit}>Зарегистрироваться</MyButton>
    </div>
  );
};

export default RegisterForm;
