import React, {useContext} from 'react';
import WeekDay from './WeekDay';
import CalendarContext from '../../context/CalendarContext';
import dayjs from 'dayjs';


const Week = ({week, setCalendarType, dayClick, makeDetailed, setEventToDetail}) => {
    const weekDays = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"]
    const {dayIndex, setDayIndex, setMonthIndex, setYearIndex, weekIndex} = useContext(CalendarContext);
    
    const moveToDay = (day) => {
        setDayIndex(day.date());
        // setMonthIndex(day.month());
        setCalendarType(0)
        dayClick()
    }

    function getCurrentDayClass(day) {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "current-day-class" : "";
    }

    const timestamps = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
    return (
        <div className='week-body'>
        <div className="week-header-table">
                {week.map((row, i) => (
                    <React.Fragment key={i}>
                        {row.map((day, i) => (
                            <div className={`week-header-table-cell month-day-text ${getCurrentDayClass(day)}`} key={i} onClick={() => moveToDay(day)}><div className='week-day-text-number'>{day.format("DD")}</div><div className='week-day-text-name'>{weekDays[i]}</div></div>
                        ))}
                    </React.Fragment>
                ))}
        </div>
            <div className='whole-new-week-body'>
                <div className='week-left-timestamps'>
                    {timestamps.map((item, i) => (
                            <div className='week-left-timestamps-item' key={i}>{item}</div>
                    ))}
                </div>
                <div className='one-week-table'>
                    {week.map((row, i) => (
                        <React.Fragment key={i}>
                            {row.map((day, idx) => (
                                <WeekDay day={day} rowIdx={i} key={idx} setCalendarType={setCalendarType} dayClick={dayClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Week;
