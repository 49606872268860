import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import MyToast from "../components/UI/toast/MyToast";
import LoginCheckForm from "../components/Auth/LoginCheckForm";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { ORGANIZATION_NAME } from "../config";


const LoginCheck = () => {
  const {generalError, setGeneralError, closeAlert} = useContext(AuthContext)

  useEffect(() => {
    document.title = "Вход | " + ORGANIZATION_NAME
  }, [])

  return (
    <>
      <MyToast error={generalError} closeAlert={closeAlert} />
      <div className="height-need">
        <div className="main2">
          <div className="two-sides-flex">
            <LoginCheckForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginCheck;
