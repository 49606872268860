import React from 'react';
import classes from './MyInputCheckbox.module.css'

const MyInputCheckbox = React.forwardRef((props, ref) => {
    return (
        <div className='check-flex'>
        <input id="happy" name="happy" type="checkbox" ref={ref} className={classes.myInput} {...props}/>
        <label htmlFor="happy">{props.naming}</label>
        </div>
    );
})

export default MyInputCheckbox;