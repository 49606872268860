import React, { useState, useContext } from 'react';
import CalendarContext from '../../context/CalendarContext';
import dayjs from 'dayjs';
import { getDay } from '../../utils/getDates';

const DayManager = () => {
    const {dayIndex, setDayIndex, monthIndex} = useContext(CalendarContext);

    const handleNextDay = () => {
        setDayIndex(dayIndex + 1);
    }

    const handlePrevDay = () => {
        setDayIndex(dayIndex - 1)
    }

    const handleToday = () => {
        setDayIndex(dayjs().date());
    }

    const dayName = getDay(dayIndex, monthIndex);

    return (
        <div className='right-settings'>
            <i className="material-icons left-right-icons" onClick={handlePrevDay}>chevron_left</i>
            <div className='day-title'>{dayName.format("DD.MM.YYYY")}</div>
            <i className="material-icons left-right-icons" onClick={handleNextDay}>chevron_right</i>
        </div>
    );
}

export default DayManager;
