import React, {useState, useEffect, useContext} from 'react';
import { getMonth } from '../../utils/getDates';
import Month from './Month';
import CalendarContext from '../../context/CalendarContext';
import dayjs from 'dayjs';

const MonthCalendar = ({setCalendarType, dayClick, weekClick, makeDetailed, setEventToDetail}) => {
    const [currentMonth, setCurrentMonth] = useState(getMonth())
    const { monthIndex, yearIndex } = useContext(CalendarContext);
    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);
    return (
        <React.Fragment>
                <Month month={currentMonth} monthIndex={monthIndex} setCalendarType={setCalendarType} dayClick={dayClick} weekClick={weekClick} makeDetailed={makeDetailed} setEventToDetail={setEventToDetail}/>
        </React.Fragment>
    );
}

export default MonthCalendar;
