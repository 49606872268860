import React from 'react';
import MyButton from './UI/button/MyButton';
import { useNavigate } from 'react-router-dom';


const SuccessCreate = ({closeMessage, intersections}) => {

    const navigate = useNavigate();

    const redirectToCalendar = () => {
        closeMessage();
        navigate("/", { replace: true });
    }

    return (
        <>
        <div className="background-block"></div>
            <div className="instruction-body">
                <i className="material-icons close-instruction-icon" onClick={closeMessage}>close</i>
                <div className="instruction-title">Мероприятие создано{intersections && '. НО!'}</div>
                <div className="instruction-text">
                    {intersections && <p className='logout-text bold-text'>Ваше мероприятие пересекается с другими для некоторых классов. Обратите на это внимание!</p>}
                    <p className='logout-text'>Как только созданное мероприятие будет согласовано, оно появится в календаре.</p>
                    <MyButton onClick={redirectToCalendar}>В Календарь</MyButton>
                </div>
        </div>

    </>
    );
}

export default SuccessCreate;
