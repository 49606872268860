export const moduleOptions = [
    { value: 'm1', label: 'Модуль ВР 1', color: '#00B8D9' },
    { value: 'm2', label: 'Модуль ВР 2', color: '#0052CC' },
    { value: 'm3', label: 'Модуль ВР 3', color: '#5243AA' }
]

export const divisionOptions = [
    { value: 'k1', label: 'Корпус 1' },
    { value: 'k2', label: 'Корпус 2' },
    { value: 'k3', label: 'Корпус 3' }
]

export const parallelOptions = [
    { value: 1, label: '1 параллель' },
    { value: 2, label: '2 параллель' },
    { value: 3, label: '3 параллель' },
    { value: 4, label: '4 параллель' },
    { value: 5, label: '5 параллель' },
    { value: 6, label: '6 параллель' },
    { value: 7, label: '7 параллель' },
    { value: 8, label: '8 параллель' },
    { value: 9, label: '9 параллель' },
    { value: 10, label: '10 параллель' },
    { value: 11, label: '11 параллель' },
]

export const classOptions = [
    { value: 'p1', label: '1A ' },
    { value: 'p2', label: '2A ' },
    { value: 'p3', label: '3A ' },
    { value: 'p4', label: '4A ' },
    { value: 'p5', label: '5A ' },
    { value: 'p6', label: '6A ' },
    { value: 'p7', label: '7A ' },
    { value: 'p8', label: '8A ' },
    { value: 'p9', label: '9A ' },
    { value: 'p10', label: '10A ' },
    { value: 'p11', label: '11A ' },
    { value: 'p1_2', label: '1Б ' },
    { value: 'p2_2', label: '2Б ' },
    { value: 'p3_2', label: '3Б ' },
    { value: 'p4_2', label: '4Б ' },
    { value: 'p5_2', label: '5Б ' },
    { value: 'p6_2', label: '6Б ' },
    { value: 'p7_2', label: '7Б ' },
    { value: 'p8_2', label: '8Б ' },
    { value: 'p9_2', label: '9Б ' },
    { value: 'p10_2', label: '10Б ' },
    { value: 'p11_2', label: '11Б ' },
]

export const eventsData = [
    {
        name: 'Событие 1',
        id: 1231321312,
        classes: ['1А класс', '2А класс', '3А класс', '4А класс', '4Б класс'],
        date: '2024-06-25',
        timeStart: '07:00',
        timeEnd: '15:30',
        place: 'Медиатека',
        documents: ["Приказ №1", "Свидетельство о регистрации"],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'red',
        isExternal: false,
        confirmRequired: true,
        alignment: 1,
    },
    {
        name: 'Событие 1.2',
        id: 12313213112331,
        classes: ['1А класс', '2А класс', '3А класс', '4А класс', '4Б класс'],
        date: '2024-06-25',
        timeStart: '10:00',
        timeEnd: '11:30',
        place: 'Медиатека',
        documents: ["Приказ №1", "Свидетельство о регистрации"],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'green',
        isExternal: false,
        confirmRequired: true,
        alignment: 2,
    },
    {
        name: 'Событие 1.3',
        id: 123132131421,
        classes: ['1А класс', '2А класс', '3А класс', '4А класс', '4Б класс'],
        date: '2024-06-25',
        timeStart: '11:00',
        timeEnd: '13:45',
        place: 'Медиатека',
        documents: ["Приказ №1", "Свидетельство о регистрации"],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'red',
        isExternal: false,
        confirmRequired: true,
        alignment: 3,
    },
    {
        name: 'Событие 2 длинное название',
        id: 1231321313,
        classes: ['5А класс', '6А класс', '7А класс', '8А класс', '9Б класс'],
        date: '2024-06-12',
        timeStart: '12:00',
        timeEnd: '16:00',
        place: 'Спортивная площадка',
        documents: ["Приказ №1", "Свидетельство о регистрации"],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'blue',
        isExternal: true,
        confirmRequired: false,
    },
    {
        name: 'Событие 3',
        id: 1231321314,
        classes: ['11А класс', '11Б класс', '10А класс', '10Б класс'],
        date: '2024-06-12',
        timeStart: '13:00',
        timeEnd: '17:00',
        place: 'Спортивная площадка',
        documents: [],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'red',
        isExternal: true,
        confirmRequired: false,
    },
    {
        name: 'Событие 1488',
        id: 1231321314123213214,
        classes: ['11А класс', '11Б класс', '10А класс', '10Б класс'],
        date: '2024-07-07',
        timeStart: '13:00',
        timeEnd: '17:00',
        place: 'Спортивная площадка',
        documents: [],
        comment: 'Дополнительная информация о событии',
        moduleVR: 'Такой то модуль',
        color: 'green',
        isExternal: true,
        confirmRequired: false,
    }
]