import React from 'react';
import MyButton from './UI/button/MyButton';

const LogoutConfirm = ( {closeMessage, logoutUser} ) => {
    return (
    <>
        <div className="background-block"></div>
            <div className="instruction-body">        
                <i className="material-icons close-instruction-icon" onClick={closeMessage}>close</i>
                <div className="instruction-title">Выйти из аккаунта?</div>
                <div className="instruction-text">
                    <p className='logout-text'>Если вы выйдете из профиля, для входа потребуется снова ввести данные, полученные при регистрации.</p>
                    {/* <a href="https://t.me/Auth619_bot" target="_blank" rel="noreferrer"><img src="../assets/images/tg.svg" alt="tg" /></a>
                    <p>Если что-то идёи не так, Вы можете войти в профиль используя адрес электронной почты, указанный при регистрации.</p> */}
                    <MyButton onClick={() => logoutUser(false)}>Выйти</MyButton>
                </div>
        </div>

    </>
    );
}

export default LogoutConfirm;
