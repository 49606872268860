import React, {useState} from 'react';
import CalendarContext from './CalendarContext';
import dayjs from 'dayjs';
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)


const CalendarContextWrapper = (props) => {
    const [yearIndex, setYearIndex] = useState(dayjs().year())
    const [monthIndex, setMonthIndex] = useState(dayjs().month())
    const [weekIndex, setWeekIndex] = useState(dayjs().week())
    const [dayIndex, setDayIndex] = useState(dayjs().date())
    const [events, setEvents] = useState([])
    const [calendarType, setCalendarType] = useState(2)


    return (
        <CalendarContext.Provider value={{monthIndex, setMonthIndex, weekIndex, setWeekIndex, dayIndex, setDayIndex, events, setEvents, calendarType, setCalendarType, yearIndex, setYearIndex}}>
            {props.children}
        </CalendarContext.Provider>
    );
}

export default CalendarContextWrapper;
