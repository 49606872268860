import React, { useState, useContext, useEffect } from "react";
import MyInputText from "../UI/input/MyInputText";
import MyButton from "../UI/button/MyButton";
import AuthContext from "../../context/AuthContext";

const LoginCheckForm = () => {
  const [entered, setEntered] = useState(null);
  let { loginUser } = useContext(AuthContext);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      loginUser(entered);
    }
  }

  return (
    <div>
      <div>
        <div className="register-form-block">
          <div className="enter-name-block">
            Введите код подтверждения:
            <MyInputText onChange={(event) => setEntered(event.target.value)} onKeyDown={handleKeyDown} />
          </div>
        </div>
        <MyButton
          onClick={() => {
            loginUser(entered);
          }}
        >
          Подтвердить
        </MyButton>
      </div>
    </div>
  );
};

export default LoginCheckForm;
