import React, {useContext, useState, useRef} from "react";
import { useEffect } from "react";
import AuthContext from "../../../context/AuthContext";
import { CSSTransition } from 'react-transition-group';


const MyToast = (props) => {
    const { error = [], closeAlert = Function.prototype } = props;
    const {generalError} = useContext(AuthContext)
    const [toastVisible, setToastVisible] = useState(false)
    const toastRef = useRef(null)
    const [displayedError, setDisplayedError] = useState(error)

    // useEffect(() => {
    //   const timerId = setTimeout(closeAlert, 5000);
    //   return () => {
    //     clearTimeout(timerId);
    //   };
    // }, [error]);
    
    useEffect(() => {
        if (generalError.length > 0) {
            setToastVisible(true)
        } else {
            setToastVisible(false)
        }
    }, [generalError])

    useEffect(() => {
        if (generalError !== '') {
            setTimeout(setDisplayedError(error), 5000)
        }
    }, [error])

    return (
        <>
        {true &&
        <CSSTransition
            in={toastVisible}
            timeout={500}
            classNames="alertmessage"
            mountOnEnter
            unmountOnExit
            nodeRef={toastRef}
        >
        
        <div className="alertmessageold" ref={toastRef}>
            <div className="messagebodypair">
                {/* {displayedError[0].length > 0 && <div className="message-body">Код ошибки: {displayedError[0]}</div>} */}
                <div className="message-body">{displayedError[1]}</div>
            </div>
            <i
                className={`material-icons messageclose`}
                onClick={closeAlert}
            >
                close
            </i>
        </div>
        
        </CSSTransition>
            }
        </>
    );
};

export default MyToast;
