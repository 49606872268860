function NotFound() {
  return (
    <>
      <div className="context">
        <h1>Страница по данному адресу не существует или перемещена</h1>
      </div>
      <div className="area">
        <ul className="squares">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  );
}

export default NotFound;
