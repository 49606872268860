import React from "react";

const CalendarContext = React.createContext({
    yearIndex: 0,
    setYearIndex: (index) => {},
    monthIndex: 0,
    setMonthIndex: (index) => {},
    weekIndex: 0,
    setWeekIndex: (index) => {},
    dayIndex: 0,
    setDayIndex: (index) => {},
    events: [],
    setEvents: (events) => [],
    calendarType: 1,
    setCalendarType: (type) => {},
});


export default CalendarContext;