import dayjs from "dayjs";
var weekOfYear = require('dayjs/plugin/weekOfYear')
var dayOfYear = require('dayjs/plugin/dayOfYear')
var localization = require('dayjs/locale/ru')
dayjs.locale(localization)
dayjs.extend(weekOfYear)
dayjs.extend(dayOfYear)

export function getMonth(month = dayjs().month()) {
    const monthOk = month % 12
    const year = dayjs().year();
    const firstDayOfMonth = dayjs(new Date(year, month, 0)).day()
    if (firstDayOfMonth === 5 && (monthOk % 2 === 0 || monthOk === 0)) {
        let currentMonthCount = 0 - firstDayOfMonth
        const daysMatrix = new Array(6).fill([]).map(() => {return new Array(7).fill(null).map(() => {
            currentMonthCount++
            return dayjs(new Date(year, month, currentMonthCount))
        })}
        );
        return daysMatrix;
    } else if (firstDayOfMonth === 6) {
        let currentMonthCount = 0 - firstDayOfMonth
        const daysMatrix = new Array(6).fill([]).map(() => {return new Array(7).fill(null).map(() => {
        currentMonthCount++
        return dayjs(new Date(year, month, currentMonthCount))
        })}
        );
        return daysMatrix;
    } else {
        let currentMonthCount = 0 - firstDayOfMonth
        const daysMatrix = new Array(5).fill([]).map(() => {return new Array(7).fill(null).map(() => {
        currentMonthCount++
        return dayjs(new Date(year, month, currentMonthCount))
        })}
        );
        return daysMatrix;
    }
}

// export function getWeek(month = dayjs().month()) {
//     const year = dayjs().year();
//     const firstDayOfMonth = dayjs(new Date(year, month, 0)).day()
//     let currentMonthCount = 0 - firstDayOfMonth
//         const daysMatrix = new Array(1).fill([]).map(() => {return new Array(7).fill(null).map(() => {
//         currentMonthCount++
//         return dayjs(new Date(year, month, currentMonthCount))
//         })}
//         );
//         console.log(daysMatrix)
//         return daysMatrix;
    
// }

export function getWeek(week = dayjs().week(), yearIndex = dayjs().year()) {
    const year = yearIndex
    const startOfWeek = dayjs(`${year}-01-01`).startOf('year').add(week - 1, 'week').startOf('week');
      const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(startOfWeek.add(i, 'day'));
    }
    return [weekDays];
}

export function getDay(day = dayjs().date(), month = dayjs().month()) {
    const year = dayjs().year()
    const date = dayjs(new Date(year, month, day))
    return date;
}

