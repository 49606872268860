// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyTextarea_myTextarea__2G1kN {
    padding: 5px 5px;
    border: 2px solid #813630;
    border-radius: 8px;
    font-size: 20px;
    font-family: 'Inter', serif;
    background: transparent;
    resize: vertical;
    min-height: 140px;
    max-height: 800px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/textarea/MyTextarea.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".myTextarea {\n    padding: 5px 5px;\n    border: 2px solid #813630;\n    border-radius: 8px;\n    font-size: 20px;\n    font-family: 'Inter', serif;\n    background: transparent;\n    resize: vertical;\n    min-height: 140px;\n    max-height: 800px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myTextarea": `MyTextarea_myTextarea__2G1kN`
};
export default ___CSS_LOADER_EXPORT___;
