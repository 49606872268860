// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyButton_myBtn__2yioc {
    padding: 10px 25px;
    color: #212529;
    font-size: 18px;
    background: transparent;
    border: 2px solid #813630;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    font-family: 'Rubik', serif;
    max-width: 250px;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.MyButton_myBtn__2yioc:hover, .MyButton_myBtn__2yioc.MyButton_active__asfJN {
    color: white;
    background-color: #813630;
    transition: 0.3s;
}

.MyButton_myBtn__2yioc:disabled {
    padding: 10px 25px;
    color: #212529;
    font-size: 18px;
    background: rgba(157, 157, 157, 0.4);
    border: 2px solid #813630;
    border-radius: 10px;
    cursor: auto;
    transition: 0.3s;
    font-family: 'Rubik', serif;
    max-width: 250px;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

@media (max-width: 480px) {
    .MyButton_myBtn__2yioc {
        font-size: 14px;
        padding: 8px 20px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/button/MyButton.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,gBAAgB;IAEhB,oBAAoB;IAEpB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,oCAAoC;IACpC,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,gBAAgB;IAEhB,oBAAoB;IAEpB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;QACf;IACJ;AACJ","sourcesContent":[".myBtn {\n    padding: 10px 25px;\n    color: #212529;\n    font-size: 18px;\n    background: transparent;\n    border: 2px solid #813630;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: 0.3s;\n    font-family: 'Rubik', serif;\n    max-width: 250px;\n    -moz-user-select: -moz-none;\n    -o-user-select: none;\n    -khtml-user-select: none;\n    -webkit-user-select: none;\n    user-select: none;\n}\n\n.myBtn:hover, .myBtn.active {\n    color: white;\n    background-color: #813630;\n    transition: 0.3s;\n}\n\n.myBtn:disabled {\n    padding: 10px 25px;\n    color: #212529;\n    font-size: 18px;\n    background: rgba(157, 157, 157, 0.4);\n    border: 2px solid #813630;\n    border-radius: 10px;\n    cursor: auto;\n    transition: 0.3s;\n    font-family: 'Rubik', serif;\n    max-width: 250px;\n    -moz-user-select: -moz-none;\n    -o-user-select: none;\n    -khtml-user-select: none;\n    -webkit-user-select: none;\n    user-select: none;\n}\n\n@media (max-width: 480px) {\n    .myBtn {\n        font-size: 14px;\n        padding: 8px 20px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myBtn": `MyButton_myBtn__2yioc`,
	"active": `MyButton_active__asfJN`
};
export default ___CSS_LOADER_EXPORT___;
