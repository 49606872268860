import React, { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import CalendarContext from "../../context/CalendarContext";

const WeekDay = ({
    day,
    rowIdx,
    setCalendarType,
    dayClick,
    makeDetailed,
    setEventToDetail,
}) => {
    const timestamps = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
    ];
    const [dayEvents, setDayEvents] = useState([]);
    const { events, setEvents } = useContext(CalendarContext);

    useEffect(() => {
        let thisDayEvents = events.filter((event) =>
            dayjs(day.format("YYYY-MM-DD")).isBetween(
                dayjs(event.date_start),
                dayjs(event.date_end),
                "day",
                "[]"
            )
        );
        thisDayEvents.map((item, index) => {
            item.alignment = index + 1;
        });

        let counter = 1
        for (let index = 0; index < thisDayEvents.length; index++) {
            const element = thisDayEvents[index];
            element.intersect_in_hour = false;
            element.intersect_querry = 0;
            if (index !== 0) {
                let currentEventStart = dayjs(thisDayEvents[index].date_start);
                let currentEventEnd = dayjs(thisDayEvents[index].date_end);
                let previousEventStart = dayjs(thisDayEvents[index - 1].date_start)
                let previousEventEnd = dayjs(thisDayEvents[index - 1].date_end)
                let timeDifference = currentEventStart.diff(previousEventStart, 'm')
                if (timeDifference < 50) {
                    element.intersect_in_hour = true;
                }
                if (currentEventStart.isBefore(previousEventEnd)) {
                    element.intersect_querry = counter;
                    counter ++
                } else {
                    counter = 1
                    element.intersect_querry = 0;
                }
            }
        }

        setDayEvents(thisDayEvents);
    }, [events, day]);

    const checkForZero = (number) => {
        if (number[0] === "0") {
            return number[1];
        } else {
            return number;
        }
    };

    const timeToNumber = (time) => {
        return (
            Number(checkForZero(time.slice(0, 2))) * 60 +
            Number(time.slice(3, 5))
        );
    };

    const setDetailedEvent = (event) => {
        setEventToDetail(event);
        makeDetailed();
    };

    return (
        <div className="week-day-body">
            <div className="week-day-timestamps">
                {timestamps.map((item, i) => (
                    <div className="week-day-one-chunck" key={i}></div>
                ))}
            </div>
            {dayEvents.map((event, i) => (
                <div
                    className="week-day-one-event"
                    style={{
                        top:
                            String(
                                (timeToNumber(
                                    dayjs(event.date_start).format("HH:mm")
                                ) *
                                    450) /
                                    540
                            ) + "px",
                        height:
                            String(
                                (timeToNumber(
                                    dayjs(event.date_end).format("HH:mm")
                                ) *
                                    450) /
                                    540 -
                                    (timeToNumber(
                                        dayjs(event.date_start).format("HH:mm")
                                    ) *
                                        450) /
                                        540
                            ) + "px",
                        left: String(event.alignment * 3 - 2) + "px",
                    }}
                    onClick={() => setDetailedEvent(event)}
                    key={i}
                >
                    <div className="week-event-time week-event-text" style={{
                        marginTop: String(event.intersect_in_hour ? String(event.intersect_querry * 40) + "px" : "0px")
                    }}>
                        {dayjs(event.date_start).format("HH:mm")}
                    </div>
                    <div className="week-event-name week-event-text">
                        {event.name}
                    </div>
                    <div className="week-event-place week-event-text">
                        {event.place}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WeekDay;
