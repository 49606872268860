import React from 'react';
import MyButton from './UI/button/MyButton';

const DeleteEventConfirm = ({eventName, closeMessage, deleteEvent}) => {
    return (
        <>
        <div className="background-block"></div>
            <div className="instruction-body">        
                <i className="material-icons close-instruction-icon" onClick={closeMessage}>close</i>
                <div className="instruction-title">Удаление события</div>
                <div className="instruction-text">
                    <p className='logout-text'>Вы точно хотите удалить событие "{eventName}"?</p>
                    <MyButton onClick={() => deleteEvent()}>Удалить</MyButton>
                </div>
        </div>
    </>
    );
}

export default DeleteEventConfirm;
