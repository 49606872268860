import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import CalendarContext from '../../context/CalendarContext';

const MonthDay = ({day, rowIdx, monthIndex, setCalendarType, dayClick, makeDetailed, setEventToDetail}) => {
    var isBetween = require('dayjs/plugin/isBetween')
    dayjs.extend(isBetween)
    const [dayEvents, setDayEvents] = useState([]);
    const {dayIndex, setDayIndex, setMonthIndex, setYearIndex} = useContext(CalendarContext);
    const {events, setEvents} = useContext(CalendarContext)
    const [multiEventsQuantity, setMultiEventsQuantity] = useState(0)

    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY") ? "current-day-class" : "";
    }

    function getOtherMonthDaysClass() {
        let newMonthIndex = monthIndex
        if (monthIndex < 0) {
            newMonthIndex = 12 * ((Math.floor(Math.abs(monthIndex) / 12)) + 1) + monthIndex
        }
        return day.format("MM") != ((newMonthIndex % 12) + 1) ? "other-month-day-class" : "";
    }

    const moveToDay = () => {
        setDayIndex(day.date());
        setCalendarType(0)
        dayClick()
    }

    const moveToDayMobile = () => {
        let width = window.innerWidth;
        if (width < 768) {
            setDayIndex(day.date());
            setCalendarType(0)
            dayClick()
        }
    }

    const setDetailedEvent = (event) => {
        setEventToDetail(event)
        makeDetailed()
    }

    const checkMultiEvent = (event) => {
        if (dayjs(event.date_start).format("YYYY-MM-DD") === dayjs(event.date_end).format("YYYY-MM-DD")) {
            return true;
        } else {
            return false;
        }
    }

    const checkMultiEventStart = (event, day) => {
        return day.format("DD-MM-YYYY") === dayjs(event.date_start).format("DD-MM-YYYY") ? "multi-event-start" : "";
    }

    const checkMultiEventEnd = (event, day) => {
        return day.format("DD-MM-YYYY") === dayjs(event.date_end).format("DD-MM-YYYY") ? "multi-event-end" : "";
    }

    const calcMultiEvents = () => {
        let counter = 0
        events.map(event => {
            if (dayjs(event.date_start).format("YYYY-MM-DD") === dayjs(event.date_end).format("YYYY-MM-DD")) {
                counter += 1
            } else {
                counter += 0
            }
        })
        setMultiEventsQuantity(counter);
    }


    useEffect(() => {
        // const thisDayEvents = events.filter(event => event.date === day.format("YYYY-MM-DD"));
        calcMultiEvents()
        const thisDayEvents = events.filter(event => dayjs(day.format("YYYY-MM-DD")).isBetween(dayjs(event.date_start), dayjs(event.date_end), 'day', '[]'));
        setDayEvents(thisDayEvents);
    }, [events, day])
    
    return (
        <div className='month-day-body' onClick={moveToDayMobile}>
            <header className='month-day-header'>
                {/* {rowIdx === 0 && <p className="month-day-title">
                    {day.format('ddd').toUpperCase()}
                </p>} */}
                <p className={`month-day-text ${getCurrentDayClass()} ${getOtherMonthDaysClass()}`} onClick={moveToDay}>
                    {day.format('DD')}
                </p>
            </header>
            <div className="month-day-events">
                {dayEvents.map(event => (
                    // <>
                    // { checkMultiEvent(event) ? 
                    // <div className={`month-day-event ${getOtherMonthDaysClass()}`} key={event.id} onClick={() => setDetailedEvent(event)}><div className='event-dot' style={{"backgroundColor": event.module.color_code}}></div><div className='event-text'>{dayjs(event.date_start).format('HH:mm')}{" "}{event.name}</div></div>
                    // : 
                    // <div className={`month-day-event-multi ${getOtherMonthDaysClass()} ${checkMultiEventStart(event, day)} ${checkMultiEventEnd(event, day)}`} style={{"backgroundColor": event.module.color_code + "FF", "visibility": ""}} key={event.id + "123"} onClick={() => setDetailedEvent(event)}><div className='event-text'>{day.format("DD-MM-YYYY") === dayjs(event.date_start).format("DD-MM-YYYY") ? dayjs(event.date_start).format('HH:mm') : ""}{" "}{ day.format("DD-MM-YYYY") === dayjs(event.date_start).format("DD-MM-YYYY") ? event.name : ""}</div></div>
                    // }
                    // </>
                    <div className={`month-day-event ${getOtherMonthDaysClass()}`} key={event.id} onClick={() => setDetailedEvent(event)}><div className='event-dot' style={{"backgroundColor": event.module.color_code}}></div><div className='event-text'>{dayjs(event.date_start).format('HH:mm')}{" "}{event.name}</div></div>
                ))}
            </div>
        </div>
    );
}

export default MonthDay;
