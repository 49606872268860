import React, { useContext, useState, useEffect } from "react";
import CalendarContext from "../../context/CalendarContext";
import dayjs from "dayjs";
var isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

const Day = ({
    day,
    makeDetailed,
    setEventToDetail,
    weekClick,
    monthClick,
}) => {
    const timestamps = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
    ];
    const timestamps2 = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00",
    ];
    const [dayEvents, setDayEvents] = useState([]);
    const { events, setEvents } = useContext(CalendarContext);
    const { setCalendarType } = useContext(CalendarContext);
    const { setMonthIndex, setWeekIndex, setYearIndex, monthIndex } =
        useContext(CalendarContext);

    useEffect(() => {
        let thisDayEvents = events.filter(event => dayjs(day.format("YYYY-MM-DD")).isBetween(dayjs(event.date_start), dayjs(event.date_end), 'day', '[]'));
        thisDayEvents.map((item, index) => {item.alignment = index + 1})
        let counter = 1
        for (let index = 0; index < thisDayEvents.length; index++) {
            const element = thisDayEvents[index];
            element.intersect_in_hour = false;
            element.intersect_querry = 0;
            if (index !== 0) {
                let currentEventStart = dayjs(thisDayEvents[index].date_start);
                let currentEventEnd = dayjs(thisDayEvents[index].date_end);
                let previousEventStart = dayjs(thisDayEvents[index - 1].date_start)
                let previousEventEnd = dayjs(thisDayEvents[index - 1].date_end)
                let timeDifference = currentEventStart.diff(previousEventStart, 'm')
                if (timeDifference < 50) {
                    element.intersect_in_hour = true;
                }
                if (currentEventStart.isBefore(previousEventEnd)) {
                    element.intersect_querry = counter;
                    counter ++
                } else {
                    counter = 1
                    element.intersect_querry = 0;
                }
            }
        }
        setDayEvents(thisDayEvents);
    }, [events, day]);

    const checkForZero = (number) => {
        if (number[0] === "0") {
            return number[1];
        } else {
            return number;
        }
    };

    const timeToNumber = (time) => {
        return (
            Number(checkForZero(time.slice(0, 2))) * 60 +
            Number(time.slice(3, 5))
        );
    };

    const setDetailedEvent = (event) => {
        setEventToDetail(event);
        makeDetailed();
    };

    const moveToWeek = () => {
        let weekIndex = day.isoWeek();
        const startOfWeek = dayjs(`${day.year()}-01-01`).startOf('year').add(weekIndex - 1, 'week').startOf('week');
        const weekDays = [];
        for (let i = 0; i < 7; i++) {
        weekDays.push(startOfWeek.add(i, 'day'));
        }
        console.log(weekDays, day)
        if (dayjs(day.format("YYYY-MM-DD")).isBefore(dayjs(weekDays[0].format("YYYY-MM-DD")))) {
            console.log('before')
            setWeekIndex(day.isoWeek() - 1);
        } else if (dayjs(day.format("YYYY-MM-DD")).isAfter(dayjs(weekDays[6].format("YYYY-MM-DD")))) {
            console.log('after')
            setWeekIndex(day.isoWeek() + 1);
        } else {
            console.log('between')
            setWeekIndex(day.isoWeek());
        }
        setYearIndex(day.year());
        weekClick();
        setCalendarType(1);
    };
    // const moveToMonth = () => {
    //     const yearByDay = day.year();
    //     const yearByMonth = dayjs(
    //         new Date(dayjs().year(), monthIndex, day.date())
    //     ).year();
    //     const yearDelta = yearByDay - yearByMonth;
    //     console.log(yearByDay, yearByMonth);
    //     if (monthIndex > -1) {
    //         if (day.month() > monthIndex) {
    //             setMonthIndex(day.month());
    //         } else {
    //             let delta = (monthIndex % 12) - day.month();
    //             setMonthIndex(monthIndex - delta + 12 * yearDelta);
    //         }
    //     } else {
    //         const backYearFactor = Math.floor(Math.abs(monthIndex) / 12) + 1;
    //         const newMonthIndex =
    //             12 * (Math.floor(Math.abs(monthIndex) / 12) + 1) + monthIndex;
    //         console.log(newMonthIndex);
    //         if (day.month() > newMonthIndex) {
    //             setMonthIndex(day.month());
    //         } else {
    //             let delta = (newMonthIndex % 12) - day.month();
    //             setMonthIndex(
    //                 newMonthIndex - delta + 12 * yearDelta - 12 * backYearFactor
    //             );
    //         }
    //     }
    //     monthClick();
    //     setCalendarType(2);
    // };

    function moveToMonth() {
        const date = dayjs(day.format("YYYY-MM-DD"));
        const currentYear = dayjs().year();

        const yearDiff = date.year() - currentYear;

        let monthIndex = date.month() + yearDiff * 12;

        if (yearDiff === 0) {
            monthIndex = date.month();
        }

        setMonthIndex(monthIndex);
        monthClick();
        setCalendarType(2);
    }

    return (
        <div className="day-calendar-body">
            <div className="day-calendar-header">
                <div className="day-number" onClick={() => moveToMonth()}>
                    {day.format("D MMMM YYYY")}
                </div>
                <div className="day-name" onClick={() => moveToWeek()}>
                    {day.format("dddd")}
                </div>
            </div>
            <div className="whole-new-week-body day-calendar">
                <div className="week-left-timestamps">
                    {timestamps2.map((item, i) => (
                        <div className="week-left-timestamps-item" key={i}>
                            {item}
                        </div>
                    ))}
                </div>
                <div className="day-calendar-events one-day-table">
                    <div className="week-day-body">
                        <div className="week-day-timestamps">
                            {timestamps.map((item, i) => (
                                <div
                                    className="week-day-one-chunck"
                                    key={i}
                                ></div>
                            ))}
                        </div>
                        {dayEvents.map((event, i) => (
                            <div
                                className="week-day-one-event"
                                style={{
                                    top:
                                        String(
                                            (timeToNumber(dayjs(event.date_start).format("HH:mm")) *
                                                450) /
                                                540
                                        ) + "px",
                                    height:
                                        String(
                                            (timeToNumber(dayjs(event.date_end).format("HH:mm")) *
                                                450) /
                                                540 -
                                                (timeToNumber(dayjs(event.date_start).format("HH:mm")) *
                                                    450) /
                                                    540
                                        ) + "px",
                                    left:
                                        String(event.alignment * 10 - 2) + "px",
                                }}
                                onClick={() => setDetailedEvent(event)}
                                key={i}
                            >
                                <div className="week-event-time week-event-text" style={{
                        marginTop: String(event.intersect_in_hour ? String(event.intersect_querry * 40) + "px" : "0px")
                    }}>
                                    {dayjs(event.date_start).format("HH:mm")}
                                </div>
                                <div className="week-event-name week-event-text">
                                    {event.name}
                                </div>
                                <div className="week-event-place week-event-text">
                                    {event.place}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Day;
