import React from "react";
import classes from './MyLoader.module.css'

const MyLoader = () => {
  return (
    <div className={classes.loader_1}>
      <span></span>
    </div>
  );
};

export default MyLoader;
