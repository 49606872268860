import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import logo from "../assets/images/logo.svg"
import LogoutConfirm from "./LogoutConfirm";
import { useNavigate } from "react-router-dom";
import CalendarContext from "../context/CalendarContext";

const Navbar = () => {
  const {logoutUser, setLoading, user} = useContext(AuthContext)
  const iconRef = useRef(null)
  const menuRef = useRef(null)
  const [logingOut, setLogingOut] = useState(false)
  const navigate = useNavigate();
  const {calendarType, setCalendarType} = useContext(CalendarContext)
  const burgerClick = () => {
    if (iconRef) {
      const menu = menuRef.current;
      menu.classList.toggle("active")
      const icon = iconRef.current;
      icon.classList.toggle("active")
      const body = document.getElementsByClassName("App")[0]
      body.classList.toggle("lock")
    }
  }

  const monthCalendarClick = () => {
    navigate("/")
    setCalendarType(2)
    burgerClick()
  }

  const dayCalendarClick = () => {
    navigate("/")
    setCalendarType(0)
    burgerClick()
  }

  const weekCalendarClick = () => {
    navigate("/")
    setCalendarType(1)
    burgerClick()
  }
  
  const setLoadingProfile = () => {
    if (window.location.pathname !== "/profile") {
    setLoading(true)
    }
  }
  
  const setLoadingCreate = () => {
    if (window.location.pathname !== "/create") {
    setLoading(true)
    }
  }

  const setLoadingConfirm = () => {
    if (window.location.pathname !== "/confirms") {
    setLoading(true)
    }
  }

  const setLoadingEvents = () => {
    if (window.location.pathname !== "/events") {
    setLoading(true)
    }
  }

  const setLoadingCalendar = () => {
    if (window.location.pathname !== "/") {
    setLoading(true)
    }
  }

  return (
    <div>
      <div className="header">
        <div className="mobile-header"></div>
        <div className="header_burger" onClick={burgerClick} ref={iconRef}>
          <span></span>
        </div>
        <nav className="sidenav" ref={menuRef}>
          <Link to="/" className="link-nav-nav">
            <div className="pic-logout navbar-iconic-block" onClick={() => setLoadingCalendar()}>
                <img src={logo} alt="142 school logo" className="navbar-logo"/> 
            </div>
          </Link>
          <div className="pic-logout navbar-iconic-block link-nav-nav-nav">
                <img src={logo} alt="142 school logo" className="navbar-logo"/> 
            </div>
          <div className="menu-block">
            <div className="link-nav false-big-caledar-icon" onClick={() => monthCalendarClick()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">date_range</i>
            <div className="mobile-text navbar-text">Календарь</div>
            </div>
            </div>
            <Link to="/" className="link-nav true-big-caledar-icon" onClick={() => setLoadingCalendar()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">date_range</i>
            <div className="mobile-text navbar-text">Календарь</div>
            </div>
            </Link>
            <div className="link-sub-nav link-nav" onClick={() => monthCalendarClick()}>
            <div className="navbar-iconic-block">
            <div className="mobile-text navbar-text-small">Месяц</div>
            </div>
            </div>
            <div className="link-sub-nav link-nav" onClick={() => weekCalendarClick()}>
            <div className="navbar-iconic-block">
            <div className="mobile-text navbar-text-small">Неделя</div>
            </div>
            </div>
            <div className="link-sub-nav link-nav" onClick={() => dayCalendarClick()}>
            <div className="navbar-iconic-block">
            <div className="mobile-text navbar-text-small">День</div>
            </div>
            </div>
            <Link to="/create" className="link-nav" onClick={() => setLoadingCreate()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">add_circle_outline</i>
            <div className="mobile-text navbar-text">Создать событие</div>
            </div>
            </Link>
            {user.can_approve &&
            <Link to="/confirms" className="link-nav" onClick={() => setLoadingConfirm()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">playlist_add_check</i>
            <div className="mobile-text navbar-text">Согласование</div>
            </div>
            </Link>
  }
            <Link to="/events" className="link-nav" onClick={() => setLoadingEvents()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">sort</i>
            <div className="mobile-text navbar-text">Мои события</div>
            </div>
            </Link>
            <Link to="/profile" className="link-nav" onClick={() => setLoadingProfile()}>
            <div className="navbar-iconic-block">
            <i className="large material-icons navbar-icon">person_outline</i>
            <div className="mobile-text navbar-text">Профиль</div>
            </div>
            </Link>
          </div>
          <div className="menu-block link-nav" onClick={() => setLogingOut(true)}>
            <div className="navbar-iconic-block" onClick={null}>
            <i className="large material-icons navbar-icon">exit_to_app</i>
            <div className="navbar-text">Выйти</div>
            </div>
          </div>
        </nav>
      </div>
      {logingOut && <LogoutConfirm closeMessage={() => setLogingOut(false)} logoutUser={logoutUser} />}
    </div>
  );
};

export default Navbar;
