// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyInputText_myInput__FZTfs {
    width: 100%;
    padding: 5px 5px;
    border: 2px solid #813630;
    border-radius: 8px;
    font-size: 20px;
    font-family: 'Inter', serif;
    background: transparent
  }

textarea:focus, input:focus{
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/UI/input/MyInputText.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,2BAA2B;IAC3B;EACF;;AAEF;IACI,aAAa;EACf","sourcesContent":[".myInput {\n    width: 100%;\n    padding: 5px 5px;\n    border: 2px solid #813630;\n    border-radius: 8px;\n    font-size: 20px;\n    font-family: 'Inter', serif;\n    background: transparent\n  }\n\ntextarea:focus, input:focus{\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myInput": `MyInputText_myInput__FZTfs`
};
export default ___CSS_LOADER_EXPORT___;
