import React from 'react';
import MyButton from './UI/button/MyButton';
import { useNavigate } from 'react-router-dom';

const SuccessApproved = ({ closeMessage, isConfirmed }) => {
    const navigate = useNavigate();

    const redirectToCalendar = () => {
        closeMessage();
        navigate("/confirms", { replace: true });
    };

    return (
        <>
            <div className="background-block"></div>
            <div className="instruction-body">
                <i
                    className="material-icons close-instruction-icon"
                    onClick={closeMessage}
                >
                    close
                </i>
                <div className="instruction-title">
                    Мероприятие {isConfirmed ? "согласовано" : "отправлено в доработку"}
                </div>
                <div className="instruction-text">
                {isConfirmed &&
                    <p className="logout-text">
                        После внесение правок автором мероприятия, оно снова появится в механизме согласования
                    </p>
                }
                    <MyButton onClick={redirectToCalendar}>
                        К согласованию
                    </MyButton>
                </div>
            </div>
        </>
    );
};

export default SuccessApproved;
