import React, { useContext, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import MyToast from "../components/UI/toast/MyToast";
import LoginGetForm from "../components/Auth/LoginGetForm";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import { ORGANIZATION_NAME } from "../config";


const LoginGet = () => {
  const { generalError, setGeneralError, closeAlert } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Вход | " + ORGANIZATION_NAME
  }, [])

  return (
    <>
      {/* {generalError && <MyToast error={generalError} closeAlert={closeAlert} />} */}
      <MyToast error={generalError} closeAlert={closeAlert} />
      <div className="height-need">
        <div className="main2">
          <div className="two-sides-flex">
            <div className="left-login-block">
              <LoginGetForm />
              <div className="register-link">
                Нет аккаунта? <Link to="/register">Создать</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginGet;
