import { createContext, useState, useEffect, useRef, createRef } from "react";
import { jwtDecode } from "jwt-decode";
import { redirect, useNavigate } from "react-router-dom";
import { API_AUTH_URL } from "../config";
import axios from "axios";

const AuthContext = createContext();
export default AuthContext;

const toastRef = createRef();

export const AuthProvider = ({ children }) => {
    let [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens")).user_data
            : null
    );
    let [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    let [loading, setLoading] = useState(true);
    let [generalError, setGeneralError] = useState('');

    const [isRefreshingToken, setIsRefreshingToken] = useState(false); // Flag for token refresh

    const closeAlert = () => {
        // const toast = hereRef.current;
        // toast.classList.add("closed")
        setGeneralError("");
    };

    const newSetGeneralError = (error) => {
        // const toast = hereRef.current;
        // toast.classList.remove("closed")
        setGeneralError(error);
    };

    const navigate = useNavigate();

    let loginUser = async (e) => {
        axios({
            url: `${API_AUTH_URL}/api/v1/login/`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "ru",
            },
            data: JSON.stringify({ destination: localStorage.number, code: e }),
        })
            .then((res) => {
                let data = res.data;
                if (data.access_token) {
                    localStorage.setItem("authTokens", JSON.stringify(data));
                    setAuthTokens(data);
                    setUser(data.user_data);
                    navigate("/");
                }
            })
            .catch((e) => {
                console.log(e);
                if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const logoutUser401 = async () => {
        await updateToken();
        logoutUser();
    }

    const logoutUser = async (terminate_all = false) => {
        await updateToken();
        try {
        const res = await axios({
            url: `${API_AUTH_URL}/api/v1/logout/`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "ru",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
            data: JSON.stringify({ terminate_all: terminate_all }),
        })
                localStorage.removeItem("authTokens");
                setAuthTokens(null);
                setUser(null);
                navigate("/login", { replace: true });
        } catch (e) {
                console.log(e);
                if (e.response.status == 401 && !isRefreshingToken) {
                    setIsRefreshingToken(true); // Set flag to prevent recursion
                    await updateToken();
                    setIsRefreshingToken(false); // Reset flag after refresh
                    await logoutUser();
                }
                if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            };
    };

    let logoutUserDefault = () => {
    localStorage.removeItem("authTokens");
    setAuthTokens(null);
    setUser(null);
      // navigate("/login/");
      navigate('/login', {replace: true})
    };

    // const updateToken = async () => {
    //     console.log("token update")
    //     const response = await axios({
    //         url: `${API_AUTH_URL}/api/v1/refresh/`,
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: JSON.stringify({ refresh_token: authTokens.refresh_token }),
    //     });
    //     let data = response.data;
    //     if (response.status === 200) {
    //       console.log('token updated!')
    //         var oldData = authTokens;
    //         if (data.access_token) {
    //             oldData.access_token = data.access_token;
    //             setAuthTokens(oldData);
    //         }
    //         if (data.refresh_token) {
    //             oldData.refresh_token = data.refresh_token;
    //             setAuthTokens(oldData);
    //         }
    //         localStorage.setItem("authTokens", JSON.stringify(oldData));
    //     } else {
    //       console.log('token not updated!')
    //         logoutUser(false);
    //     }
    //     if (loading) {
    //         setLoading(false);
    //     }
    // };

    const updateToken = async () => {
        setLoading(true);
        console.log("token update");
        await axios({
            url: `${API_AUTH_URL}/api/v1/refresh/`,
            method: "POST",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
            },
            data: JSON.stringify({ refresh_token: authTokens.refresh_token }),
        })
            .then((response) => {
                let data = response.data;
                console.log("token updated!");
                console.log(data);
                var oldData = authTokens;
                let oldCanApprove = user
                oldCanApprove.can_approve = data.can_approve
                setUser(oldCanApprove)
                if (data.access_token) {
                    oldData.access_token = data.access_token;
                    setAuthTokens(oldData);
                }
                if (data.refresh_token) {
                    oldData.refresh_token = data.refresh_token;
                    setAuthTokens(oldData);
                }
                localStorage.setItem("authTokens", JSON.stringify(oldData));
            })
            .catch((e) => {
                console.log("token not updated!");
                setLoading(false);
                logoutUserDefault();
            });
    };

    const updateTokenDetailed = async () => {
        console.log("token update");
        await axios({
            url: `${API_AUTH_URL}/api/v1/refresh/`,
            method: "POST",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
            },
            data: JSON.stringify({ refresh_token: authTokens.refresh_token }),
        })
            .then((response) => {
                let data = response.data;
                console.log("token updated!");
                var oldData = authTokens;
                if (data.access_token) {
                    oldData.access_token = data.access_token;
                    setAuthTokens(oldData);
                }
                if (data.refresh_token) {
                    oldData.refresh_token = data.refresh_token;
                    setAuthTokens(oldData);
                }
                localStorage.setItem("authTokens", JSON.stringify(oldData));
            })
            .catch((e) => {
                console.log("token not updated!");
                logoutUser(false);
            });
    };

    const makeRequest = async (request) => {
        try {
            request();
            console.log("try");
        } catch (e) {
            console.log(e);
            if (e.status === "401") {
                updateToken();
                request();
            }
        }
    };

    let contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        generalError: generalError,
        setGeneralError: newSetGeneralError,
        closeAlert: closeAlert,
        updateToken: updateToken,
        updateTokenDetailed: updateTokenDetailed,
        loading: loading,
        setLoading: setLoading,
        isRefreshingToken: isRefreshingToken,
        setIsRefreshingToken: setIsRefreshingToken,
    };

    // useEffect(() => {
    //     const REFRESH_INTERVAL = 1000 * 60 * 0.1; // 2 minutes
    //     let interval = setInterval(() => {
    //         if (authTokens) {
    //             updateToken();
    //         }
    //     }, REFRESH_INTERVAL);
    //     return () => clearInterval(interval);
    // }, [authTokens, loading]);

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    );
};
