import React from 'react';
import classes from './MyInputText.module.css'

const MyInputText = React.forwardRef((props, ref) => {
    return (
        <input ref={ref} className={classes.myInput} {...props} style={{"width" : props.width, "textAlign" : props.text_align, "fontSize" : props.font_size}}/>
        
    );
})

export default MyInputText;
