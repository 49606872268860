import React, {useContext, useState} from 'react';
import CalendarContext from '../../context/CalendarContext';
import MyButton from '../UI/button/MyButton';
import dayjs from 'dayjs';
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

const WeekManager = () => {
    const {weekIndex, setWeekIndex, yearIndex, monthIndex} = useContext(CalendarContext)

    const handlePrevWeek = () => {
        setWeekIndex(weekIndex - 1)
    }

    const handleNextWeek = () => {
        setWeekIndex(weekIndex + 1)
    }

    const handleToday = () => {
        setWeekIndex(dayjs().week())
    }

    let startOfWeek = null;
    let endOfWeek = null;
    // console.log(yearIndex)
    // console.log(dayjs(`${yearIndex}-12-01`).week(weekIndex - 1).startOf('week'))
    // if (monthIndex < 0) {
    //     startOfWeek = dayjs(`${yearIndex}-12-01`).week(weekIndex - 1).startOf('week');
    //     endOfWeek = dayjs(`${yearIndex}-12-01`).week(weekIndex - 1).endOf('week');

    // } else {
    //     startOfWeek = dayjs(`${yearIndex}-12-01`).week(weekIndex).startOf('week');
    //     endOfWeek = dayjs(`${yearIndex}-12-01`).week(weekIndex).endOf('week');    
    // }

    if (monthIndex < 0) {
        startOfWeek = dayjs(`${yearIndex}-01-01`).startOf('year').add(weekIndex - 1, 'week').startOf('week')
        endOfWeek = dayjs(`${yearIndex}-01-01`).startOf('year').add(weekIndex - 1, 'week').endOf('week')

    } else {
        startOfWeek = dayjs(`${yearIndex}-01-01`).startOf('year').add(weekIndex - 1, 'week').startOf('week')
        endOfWeek = dayjs(`${yearIndex}-01-01`).startOf('year').add(weekIndex - 1, 'week').endOf('week') 
    }

    // const startOfWeek = dayjs(`${yearIndex}-${monthIndex%12 + 1}`).week(weekIndex).startOf('week');
    // const endOfWeek = dayjs(`${yearIndex}-${monthIndex%12 + 1}`).week(weekIndex).endOf('week');

    return (
        <div className='right-settings'>
            <i className="material-icons left-right-icons" onClick={handlePrevWeek}>chevron_left</i>
            <div className='week-title'>{startOfWeek.format('DD.MM.YYYY')} <a className='hide-on-mobile'>-</a> {endOfWeek.format('DD.MM.YYYY')}</div>
            <i className="material-icons left-right-icons" onClick={handleNextWeek}>chevron_right</i>
        </div>
    );
}

export default WeekManager;
