import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { ORGANIZATION_NAME } from "../config";

const EmailConfirm = () => {

  useEffect(() => {
    document.title = "Регистрация | " + ORGANIZATION_NAME
  }, [])

  return (
    <div className="height-need">
      <div className="main2">
        <div className="two-sides-flex">
          <div className="left-login-block">
            <p>
              На вашу почту отправленно письмо для подтверждения регистрации.
              Перейдите по ссылке, прикреплённой к письму
            </p>
            <Link to="/register/">
              <div className="register-link">Назад к регистрации</div>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirm;
