import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import MyButton from '../UI/button/MyButton';
import CalendarContext from '../../context/CalendarContext';

const MonthManager = () => {
    const {monthIndex, setMonthIndex} = useContext(CalendarContext)

    const handlePrevMonth = () => {
        setMonthIndex(monthIndex - 1)
    }

    const handleNextMonth = () => {
        setMonthIndex(monthIndex + 1)
    }

    const handleToday = () => {
        setMonthIndex(dayjs().month())
    }

    return (
        <div className='right-settings'>
            <i className="material-icons left-right-icons" onClick={handlePrevMonth}>chevron_left</i>
            <div className='month-title'>{dayjs(new Date(dayjs().year(), monthIndex)).format('MMMM YYYY')}</div>
            <i className="material-icons left-right-icons" onClick={handleNextMonth}>chevron_right</i>
        </div>
    );
}

export default MonthManager;
